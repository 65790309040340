/***
Customized Bootstrap Alerts
***/

.alert {
  border-width: 1px;

  > p {
    margin: 0;
  }

  &.alert-borderless {
    border: 0;
  }
}

@if $theme-type == "material-design" {
  .alert {
    @include border-radius($general-border-radius);
    border-width: 0;
    @extend .md-shadow-z-1;
  }
}