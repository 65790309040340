/***
Tasks Widget 
***/

.tasks-widget {

  &:after {
    clear: both;
  }

  .task-list {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      position: relative;
      padding: 10px 10px;
      border-bottom: 1px solid #F4F6F9;

      &:hover {
        background: #F4F6F9;
      }

      &.last-line {
        border-bottom: none;
      }

      &.task-done {
        background: #f6f6f6;

        &:hover {
          background: #f4f4f4;
        }

        .task-title-sp {
          text-decoration: line-through;
        }
      }

      > .task-bell {
        margin-left: 10px;
      }

      > .task-checkbox {
        float: left;
        width: 30px;

        input[type="checkbox"] {
          cursor: pointer;
        }
      }

      > .task-title {
        color: #838FA1;
        margin-right: 10px;

        .task-title-sp {
          margin-right: 5px;
        }
      }

      .task-config-btn {
        margin-top: -1px;
      }

      > .task-config {
        display: none;
        position: absolute;
        top: 7px;
        right: 10px;
      }

      &:hover > .task-config {
        display: block;
        margin-bottom: 0 !important;
      }
    }
  }

  .task-footer {
    margin-top: 5px;

    @include clearfix();
  }

}

@media only screen and (max-width: $screen-xs-min) {

  .tasks-widget {
    .task-config-btn {
      float: inherit;
      display: block;
    }

    .task-list-projects {
      li > .label {
        margin-bottom: 5px;
      }
    }
  }

}
