// clipboard

.mt-clipboard-container{
	padding: 20px;

	.mt-clipboard{
		margin: 10px 10px 10px 0;
	}
}

