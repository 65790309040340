// Global settings
@import 'variables';
@import 'components/mixins';

// General CSS Reset
@import 'components/reset-general';
    
// Material Desing Components
@import 'components/md';
                      
// General Components  
@import 'components/typography';
@import 'components/utils';
@import 'components/grid';
@import 'components/font-icons';
@import 'components/alerts';
@import 'components/badges';
@import 'components/buttons';
@import 'components/carousel';
@import 'components/charts';
@import 'components/chats';
@import 'components/close';
@import 'components/dropdowns';
@import 'components/feeds';
@import 'components/form-layouts';
@import 'components/form-repeater';
@import 'components/inputs';
@import 'components/icon-buttons';
@import 'components/input-icons';
@import 'components/labels';
@import 'components/list-groups';
@import 'components/loaders';
@import 'components/metro-icons';
@import 'components/modals';
@import 'components/notes';
@import 'components/pagination';
@import 'components/panels';
@import 'components/popovers';
@import 'components/portlets';
@import 'components/progress-bars';
@import 'components/statistic-blocks';
@import 'components/social-icons';
@import 'components/tables';
@import 'components/tabs';
@import 'components/tiles';
@import 'components/wells';
@import 'components/widgets';
@import 'components/animate';
@import 'components/headings';
@import 'components/timeline';
@import 'components/timeline-2';
@import 'components/timeline-3';
@import 'components/tasks-widget';
@import 'components/comments';
@import 'components/quick-actions';

@import 'components/app-widgets';

@import 'components/ui-sweetalert';
@import 'components/clipboard';

@import 'components/bootstrap-multiselect';

// Elements
@import 'components/elements/steps/step-1';
@import 'components/elements/lists/list-1';
@import 'components/elements/ribbons/ribbon-1';
@import 'components/elements/cards/card-1';
@import 'components/elements/overlay/overlay-1';

// Apply flat UI colors to all components
@import 'components/colors';

// System components

@import 'components/cookie-consent';
@import 'components/sidebar';