/***
Timeline 
***/

.timeline {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background: #f5f6fa;
    top: 0px;
    bottom: 0px;
    margin-left: 38px;
  }

  .timeline-item {
    margin: 0;
    padding: 0;
  }

  .timeline-badge {
    float: left;
    position: relative;
    padding-right: 30px;
    height: 80px;
    width: 80px;
  }

  .timeline-badge-userpic {
    width: 80px;
    border: 4px #f5f6fa solid;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
  }

  .timeline-badge-userpic img {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    vertical-align: middle !important;
  }

  .timeline-icon {
    width: 80px;
    height: 80px;
    background-color: #f5f6fa;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    padding-top: 30px;
    padding-left: 22px;
  }

  .timeline-icon i {
    font-size: 34px;
  }

  .timeline-body {
    position: relative;
    padding: 20px;
    margin-top: 20px;
    margin-left: 110px;
    background-color: #f5f6fa;

    @include clearfix();

    @include border-radius($general-border-radius);
  }

  .timeline-body-arrow {
    position: absolute;
    top: 30px;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 14px 0;
    border-color: transparent #f5f6fa transparent transparent;
  }

  .timeline-body-head {
    margin-bottom: 10px;
  }

  .timeline-body-head-caption {
    float: left;
  }

  .timeline-body-title {
    font-size: 16px;
    font-weight: 600;
  }

  .timeline-body-alerttitle {
    font-size: 16px;
    font-weight: 600;
  }

  .timeline-body-time {
    font-size: 14px;
    margin-left: 10px;
  }

  .timeline-body-head-actions {
    float: right;
  }

  .timeline-body-head-actions .btn-group {
    margin-top: -2px;
  }

  .timeline-body-content {
    font-size: 14px;
    margin-top: 35px;
  }

  .timeline-body-img {
    width: 100px;
    height: 100px;
    margin: 5px 20px 0 0px;
  }

  &.white-bg {
    &:before {
      background: #fff;
    }

    .timeline-badge-userpic {
      border-color: #fff;
    }

    .timeline-icon {
      background-color: #fff;
    }

    .timeline-body {
      background-color: #fff;
    }

    .timeline-body-arrow {
      border-color: transparent #fff transparent transparent;
    }
  }
}

@media (max-width: 768px) {
  .timeline {
    .timeline-body-head-caption {
      width: 100%;
    }

    .timeline-body-head-actions {
      float: left;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 480px) {
  .timeline {
    &:before {
      margin-left: 28px;
    }

    .timeline-badge {
      padding-right: 40px;
      width: 60px;
      height: 60px;
    }

    .timeline-badge-userpic {
      width: 60px;
    }

    .timeline-icon {
      width: 60px;
      height: 60px;
      padding-top: 23px;
      padding-left: 18px;
    }

    .timeline-icon i {
      font-size: 25px;
    }

    .timeline-body {
      margin-left: 80px;
    }

    .timeline-body-arrow {
      top: 17px;
    }
  }
}
