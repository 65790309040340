/***
Tiles(new in v1.1.1)
***/

.tiles {
  margin-right: -10px;

  &:before,
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }

  .tile {
    display: block;
    letter-spacing: 0.02em;
    float: left;
    height: 135px;
    width: 135px !important;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    position: relative;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 20px;
    overflow: hidden;
    border: 4px solid transparent;
    margin: 0 10px 10px 0;

    &:after,
    &:before {
      content: "";
      float: left;
    }

    &.double {
      width: 280px !important;
    }

    &.double-down {
      height: 280px !important;

      i {
        margin-top: 95px;
      }
    }

    &:hover {
      border-color: #aaa !important;
    }

    &:active,
    &.selected {
      border-color: #ccc !important;
    }

    &.selected .corner:after {
      content: "";
      display: inline-block;
      border-left: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-right: 40px solid #ccc;
      position: absolute;
      top: -3px;
      right: -3px;
    }

    &.selected .check:after {
      content: "";
      font-family: FontAwesome;
      font-size: 13px;
      content: "\f00c";
      display: inline-block;
      position: absolute;
      top: 2px;
      right: 2px;
    }

    &.icon {
      padding: 0;
    }

    &.image {
      .tile-body {
        padding: 0 !important;

        > img {
          width: 100%;
          height: auto;
          min-height: 100%;
          max-width: 100%;
        }

        h3 {
          display: inline-block;
        }
      }
    }

    .tile-body {
      height: 100%;
      vertical-align: top;
      padding: 10px 10px;
      overflow: hidden;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      color: #ffffff;
      margin-bottom: 10px;

      p {
        font-weight: 400;
        font-size: 13px;
        color: #000000;
        color: #ffffff;
        line-height: 20px;
        overflow: hidden;

        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }

        &:active {
          color: rgba(0, 0, 0, 0.4);
        }

        &:hover {
          color: #ffffff;
        }
      }

      img {
        float: left;
        margin-right: 10px;

        &.pull-right {
          float: right !important;
          margin-left: 10px;
          margin-right: 0px;
        }
      }

      > .content {
        display: inline-block;
      }

      > i {
        margin-top: 17px;
        display: block;
        font-size: 56px;
        line-height: 56px;
        text-align: center;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        padding: 0;
        margin: 0;
        line-height: 14px;

        &:hover {
          color: #ffffff;
        }
      }

      h3,
      h4 {
        margin-bottom: 5px;
      }
    }

    .tile-object {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      min-height: 30px;
      background-color: transparent;
      *zoom: 1;

      &:before,
      &:after {
        display: table;
        content: "";
      }

      &:after {
        clear: both;
      }

      > .name {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 5px;
        margin-left: 10px;
        margin-right: 15px;
        font-weight: 400;
        font-size: 13px;
        color: #ffffff;

        > i {
          vertical-align: middle;
          display: block;
          font-size: 24px;
          height: 18px;
          width: 24px;
        }
      }

      > .number {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 0;
        color: #ffffff;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.01em;
        line-height: 14px;
        margin-bottom: 8px;
        margin-right: 10px;
      }
    }
  }
}


